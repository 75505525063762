import { Row, Col } from 'antd';

function Profile() {

  return (
    <Row justify='center' align='middle' >
      <Col >
        Not Found
      </Col>
    </Row>
  );
};

export default Profile;