import { useState } from 'react';
import { Upload, message, Spin } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { Dragger } = Upload;
interface IProps {
  callback: (file: File | null, fileType: string) => void
  fileType: string
}

function DndUpload({ callback, fileType }: IProps) {
  const { t } = useTranslation();
  const [fileListState, setFileListState] = useState([])
  const [loadingFile, setLoadingFile] = useState(false)
 
  /**
   * Update the father state with the uploaded file. File type it's the key in to update in the father state
   * @param file 
   */
  const handleChange = (file: File | null) => {
    setLoadingFile(true)
    callback(file, fileType);
    setLoadingFile(false)
  };

  const props = {
    name: 'file',
    multiple: false,
    fileList: fileListState,
    beforeUpload: (file: File) => {
      callback(file, fileType);
      // Prevent upload using action return false
      // callback here
      return false;
    },
    onChange: (info: any) => {
      let fileList = [...info.fileList];
      let file: any = fileList.slice(-1);
      setFileListState(file);
      if (fileList.length) {
        message.success(t('dragAndDrop.successMsg'), 3)
      } else {
        handleChange(null);
        message.success(t('dragAndDrop.errorMsg'), 3)
      }
    }
  }

  return (
    <Dragger {...props} disabled={loadingFile} accept={'.pdf'}>
      {
        loadingFile ?
          <Spin />
          :
          <>
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p >{t('dragAndDrop.instructions')}</p>
          </>
      }
    </Dragger>
  );
}

export default DndUpload;