import { useTranslation } from 'react-i18next';
import { Table, Tooltip, Typography } from 'antd';

interface Item {
  key: string;
  f2: number;
  fiability: number;
  time: number;
  dp: number | null;
  nf: number | null;
  snr: number | null;
}

interface IProps {
  data: Item[] | undefined;
  title: string | undefined;
}

function DpoaeTable({ data, title }: IProps) {
  const { t } = useTranslation();

  const dpoaeColumns = [
    {
      title: <Tooltip placement="top" title={t('buildReport.F2')}>{'F2'}</Tooltip>,
      dataIndex: 'f2',
      editable: false,
      width: '15%'
    },
    {
      title: <Tooltip placement="top" title={t('buildReport.DP')}>{'DP'}</Tooltip>,
      dataIndex: 'dp',
      editable: true,
      width: '15%'
    },
    // Noise floor
    {
      title: <Tooltip placement="top" title={t('buildReport.NF')}>{'NF'}</Tooltip>,
      dataIndex: 'nf',
      editable: true,
      width: '15%'
    },
    // Signal noise relationship
    {
      title: <Tooltip placement="top" title={t('buildReport.SNR')}>{'SNR'}</Tooltip>,
      dataIndex: 'snr',
      editable: true,
      width: '15%'
    },
    {
      title: t('buildReport.fiability'),
      dataIndex: 'fiability',
      editable: true,
      width: '15%'
    },
    {
      title: t('buildReport.time'),
      dataIndex: 'time',
      editable: true,
      width: '15%'
    },
  ]

  return (
    <>
      <Typography.Title level={4}>{title}</Typography.Title>
      <Table
        bordered
        dataSource={data}
        columns={dpoaeColumns}
        pagination={false}
      />
    </>
  );
};

export default DpoaeTable;