import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { Row, Col, Form, Input, Button, Card, Typography } from 'antd';
import { UserOutlined, MailOutlined, ApartmentOutlined, BankOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};

interface ICenter {
  id: string,
  name:string
}
interface IState {
  username: string,
  nationalId: string,
  email: string,
  institution: string,
  centers: ICenter[],
}

function Profile(props: any) {
  const history = useHistory();
  const { t } = useTranslation();
  const [state, setState] = useState<IState>()

  useEffect(() => {
    setState({
      username: `${props.authState.firstName} ${props.authState.lastName}`,
      nationalId: props.authState.nationalId,
      email: props.authState.email,
      institution: props.authState.institution.name,
      centers: props.authState.institution.center
    })
  }
    , [props.authState])

  return (
    <div style={{ margin: '3%' }}>
      <Form name='profile' {...layout} style={{ margin: '3%' }}>
        <Row justify='center' align='top'>
          <Col xs={16} sm={16} lg={12}>
            <Card title={<Typography.Title level={2}>{t('commons.profile')}</Typography.Title>} >
              <Form.Item label={t('profile.name')}>
                <Input value={state?.username} disabled={true} prefix={<UserOutlined />} />
              </Form.Item>
              <Form.Item label={t('profile.nationalId')}>
                <Input value={state?.nationalId} disabled={true} prefix={<UserOutlined />} />
              </Form.Item>
              <Form.Item label={t('profile.email')}>
                <Input value={state?.email} disabled={true} prefix={<MailOutlined />} />
              </Form.Item>
              <Form.Item label={t('profile.institution')}>
                <Input value={state?.institution} disabled={true} prefix={<BankOutlined />} />
              </Form.Item>

              <Form.Item label={t('profile.centers')}>
                {
                  state?.centers.map(obj =>
                    <Input key={obj.name} value={obj.name} disabled={true} prefix={<ApartmentOutlined />} style={{ marginBottom: 10 }} />
                  )
                }
              </Form.Item>
            </Card>
          </Col>

          <Col xs={6} sm={6} lg={3}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button type='primary'
                onClick={() => history.push('/patients')}
                style={{ width: '100%', whiteSpace: 'normal', minHeight: 50, marginBottom: 15, marginLeft: 15 }}>
                {t('commons.patientsTable')}
              </Button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button type='primary'
                onClick={() => history.push('/buildreport')}
                style={{ width: '100%', whiteSpace: 'normal', minHeight: 50, marginBottom: 15, marginLeft: 15 }}>
                {t('commons.newReport')}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div >

  );
};

const mapStatetoProps = (state: any) => {
  return {
    authState: state.auth
  }
}

export default connect(mapStatetoProps)(Profile);