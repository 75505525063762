import { useEffect, useReducer, useState } from 'react';

interface IParameters {
  [key: string]: any
}

const useFetch = (initialValue = null) => {
  const [endpointState, setEndpointState] = useState<any | null>(initialValue)
  const [refetchState, setRefetchState] = useState<boolean>(true)
  const initialState = {
    status: 'idle',
    error: null,
    data: null,
  };

  // ({ url: <string>, method: <string>, headers: <headers object> }, <allow refetch>)
  const setFetch = (endpointObj: any | null, refetch: boolean = false) => {
    setEndpointState(endpointObj);
    if (refetch) {
      setRefetchState(true);
    }
  }

  const [state, dispatch] = useReducer((state: any, action: any) => {
    switch (action.type) {
      case 'FETCHING':
        return { ...initialState, status: 'fetching' };
      case 'FETCHED':
        return { ...initialState, status: 'fetched', data: action.payload, statusCode: action.statusCode };
      case 'FETCH_ERROR':
        return { ...initialState, status: 'error', error: action.payload };
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    let cancelRequest = false;
    if (!endpointState) return;

    const fetchData = async () => {
      const defaultHeader = {
        'Content-Type': 'application/json',
      }
      let parameters: IParameters;
      parameters = {
        method: endpointState.method ? endpointState.method : 'GET',
        headers: endpointState.headers ? endpointState.headers : defaultHeader,
      }
      if (endpointState.body) {
        parameters.body = endpointState.body;
      }

      dispatch({ type: 'FETCHING' });

      try {
        const response = await fetch(endpointState.url, parameters);
        const statusCode = response.status
        const data = await response.json();
        if (cancelRequest) return;
        dispatch({ type: 'FETCHED', payload: data, statusCode: statusCode });
        setRefetchState(false)
      }
      catch (error: any) {
        if (cancelRequest) return;
        dispatch({ type: 'FETCH_ERROR', payload: error.message });
        setRefetchState(false)
      }
    };
    // if refetch its false, still fetch after the first render (when status its idle)
    if (refetchState === false) {
      return;
    }
    // if refetch true always fetch
    fetchData();
    return function cleanup() {
      cancelRequest = true;
    };
  }, [endpointState, refetchState]);


  return [state, setFetch] as const;
};

export default useFetch;