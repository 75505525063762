import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Table, Button, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { DocsModal } from '../commons/index'
import './styles.css'

interface IDoc {
  id: string,
  upload_date: string
};
interface IDocs {
  [dpoae: string]: IDoc[] | [],
  moca: IDoc[] | [],
  mmse: IDoc[] | [],
  survey: IDoc[] | [],
  audiometry: IDoc[] | [],
};
interface IObj {
  reportId: string,
  patientId: string,
  name: string,
  nationalId: string,
  age: number,
  sex: string,
  educationLevel: number,
  center: string,
  documents:  IDocs | null
}
interface IProps {
  data: IObj[] | [];
}

function PatientsTable({ data }: IProps) {
  const history = useHistory();
  const { t } = useTranslation();
  const [searchState, setSearchState] = useState({
    searchText: '',
    searchedColumn: '',
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState<{ documents: IDocs, user: string }>()

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`${t('commons.search')} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t('commons.search')}
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            {t('commons.reset')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: 16 }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',

    render: (text: any, row: any) => {
      if (dataIndex === 'name') {
        return <Button type='link' onClick={() => history.push(`/report/${row.reportId}`)}>{text}</Button>
      }
      return text
    }
  });

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchState({ ...searchState, searchText: '' });
  };

  const viewDocuments = (documents: IDocs, row: IObj) => {
    setModalData({ documents: documents, user: row.name });
    showModal();
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: t('patientTable.name'),
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
    },

    {
      title: t('patientTable.nationalId'),
      dataIndex: 'nationalId',
      key: 'nationalId',
      ...getColumnSearchProps('nationalId'),
    },

    {
      title: t('patientTable.age'),
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: t('patientTable.sex'),
      dataIndex: 'sex',
      key: 'sex',
    },
    {
      title: t('patientTable.educationLevel'),
      dataIndex: 'educationLevel',
      key: 'educationLevel',
    },
    {
      title: t('patientTable.center'),
      dataIndex: 'center',
      key: 'center',
    },
    {
      title: t('patientTable.documents'),
      dataIndex: 'documents',
      key: 'documents',
      render: (documents: IDocs, row: IObj) => <Button type='primary' onClick={() => viewDocuments(documents, row)}>{t('patientTable.documents')}</Button>
    }
  ];

  return (
    <>
      <DocsModal modalData={modalData} isModalVisible={isModalVisible} handleOk={handleOk} />
      <Table columns={columns} rowKey={'reportId'} dataSource={data} style={{ width: '100%' }} />
    </>
  )
}

export default PatientsTable;