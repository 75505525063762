import { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom'

import useFetch from '../../hooks/useFetch'

const layout = {
  labelCol: { span: 11 },
  wrapperCol: { span: 13 },
};

function ChangePass() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [validationErrorState, setValidationErrorState] = useState({
    validation: false,
    matchingPass: false
  });
  const [state, setState] = useState({
    uid: '',
    token: ''
  });
  const [fetchState, setFetchState] = useFetch();

  useEffect(() => {
    try {
      const query = location.search.split('?')[1].split('&')
      const uid = query[0].split('=')[1];
      const token = query[1].split('=')[1];
      setState({
        uid: uid,
        token: token
      })
    }
    catch {
      history.push('/404');
    }
  }, [location.search, history]
  );

  useEffect(() => {
    if (fetchState.statusCode === 200) {
      setValidationErrorState({ validation: false, matchingPass: false });
      history.push('/login');
    }
    if (fetchState.statusCode >= 400) {
      setValidationErrorState({ validation: true, matchingPass: false });
    }
  }, [fetchState.statusCode, history]
  );

  const onFinish = (values: any) => {
    if (values.newPass !== values.confirmPass) {
      setValidationErrorState({ ...validationErrorState, matchingPass: true });
      return
    }
    setValidationErrorState({ ...validationErrorState, matchingPass: false });

    const url = `${process.env.REACT_APP_API_URL}v1/accounts/auth/password/reset/confirm/`;
    const body = JSON.stringify({
      new_password1: values.newPass,
      new_password2: values.confirmPass,
      // The id comes inb64, decode before sending
      uid: (state.uid),
      token: state.token
    });

    setFetchState({ url: url, method: 'POST', body: body }, true);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div style={{ margin: '3%' }}>
      <Row justify='center' align='middle' >
        <Typography.Title level={1}>{t('login.changepass')}</Typography.Title>
      </Row>
      <Row justify='center' align='middle' >
        <Col >
          <Form
            {...layout}
            name='basic'
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label={t('login.newpass')}
              name='newPass'
              rules={[{ required: true, message: `${t('warning.inputNew')} ${t('login.password').toLocaleLowerCase()}` }]}
            >
              <Input.Password
                type='password'
                minLength={8}
                placeholder={t('login.password')} />
            </Form.Item>

            <Form.Item
              label={t('login.confirmpass')}
              name='confirmPass'
              rules={[{ required: true, message: `${t('warning.inputNew')} ${t('login.password').toLocaleLowerCase()}` }]}
            >
              <Input.Password
                type='password'
                minLength={8}
                placeholder={t('login.password')} />
            </Form.Item>

            <Form.Item>
              <Button type='primary' htmlType='submit' style={{ marginTop: 15 }}>
                {t('commons.submit')}
              </Button>
            </Form.Item>
          </Form>
          {
            validationErrorState.validation
              ?
              <Typography.Text type='danger'>
                {t('login.passValidationError')}
              </Typography.Text>
              :
              null
          }
          {
            validationErrorState.matchingPass
              ?
              <Typography.Text type='danger'>
                {t('login.matchingPassError')}
              </Typography.Text>
              :
              null
          }
        </Col>
      </Row>
    </div>
  );
};

export default ChangePass;