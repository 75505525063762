const getProfileData = async (token) => {
  const defaultHeader = {
    'Content-Type': 'application/json',
    'Authorization': `JWT ${token}`
  }
  const parameters = {
    method: 'GET',
    headers: defaultHeader,
  }
  const USER_API_ENDPOINT = `${process.env.REACT_APP_API_URL}v1/accounts/user/`;

  try {
    const response = await fetch(USER_API_ENDPOINT, parameters);
    //const statusCode = response.status
    const data = await response.json();
    return data;
  }
  catch (error) {
    return {};
  }
};

export const loginUserService = (request) => {
  const LOGIN_API_ENDPOINT = `${process.env.REACT_APP_API_URL}v1/accounts/auth/login/`;
  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email: request.credentials.user, password: request.credentials.pass })
  };

  return new Promise(async (resolve, reject) => {
    let responseData;
    try {
      fetch(LOGIN_API_ENDPOINT, parameters)
        .then(response => {
          if (response.status >= 400) {
            return null
          }
          else {
            return response.json();
          }
        }
        )
        .then((data) => {
          // If login succes, try to get user data
          if (data != null) {
            responseData = {
              token: data.access_token,
              userId: data.user.pk,
              email: data.user.email
            };
            // Get user data
            getProfileData(data.access_token).then(userData => {
              if (userData) {
                const newData = {
                  firstName: userData.first_name,
                  lastName: userData.last_name,
                  nationalId: userData.national_id,
                  institution: userData.institution
                }
                responseData = { ...responseData, ...newData }
              }
              return resolve(responseData);
            })

          }
          else {
            return reject("bad login")
          }
        }
        )
    } catch (error) {
      return reject(error);
    }
  });
};

export const logoutUserService = (request) => {
  const LOGIN_API_ENDPOINT = `${process.env.REACT_APP_API_URL}v1/accounts/auth/logout/`;
  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `JWT ${request.token}`
    },
  };

  return new Promise(async (resolve, reject) => {
    try {
      fetch(LOGIN_API_ENDPOINT, parameters)
        .then(response => {
          if (response.status >= 400) {
            return null
          }
          else {
            return response.json();
          }
        }
        )
        .then((data) => {
          if (data != null) {
            resolve();
          }
          else {
            reject()
          }
        }
        )
    } catch (error) {
      reject(error);
    }
  });
};