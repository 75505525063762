import { useTranslation } from 'react-i18next';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

import './custom-theme.css';
//import 'antd/dist/antd.css'

import { Login, ResetPass, ChangePass, PatientTable, BuildReport, Report, Profile, Home, NotFound } from './containers/index'
import { Navbar, Footer } from './commons/index'
import PrivateRoute from './routerWrapper/PrivateRoute'

function App() {
  const { t } = useTranslation();
  document.title = t('title');
  return (
    <Router>
      <div style={{ minHeight: '95vh' }}>
        <Navbar />
        <Switch>
          <Route exact path='/'>
            <Home/>
          </Route>

          <Route exact path='/login'>
            <Login />
          </Route>

          <Route exact path='/resetpassword'>
            <ResetPass />
          </Route>

          <Route exact path='/resetpassword/confirm'>
            <ChangePass />
          </Route>

          <PrivateRoute exact path='/patients'>
            <PatientTable />
          </PrivateRoute>

          <PrivateRoute exact path='/buildreport'>
            <BuildReport />
          </PrivateRoute>
          <PrivateRoute exact path='/report/:patientid'>
            <Report />
          </PrivateRoute>

          <PrivateRoute exact path='/profile'>
            <Profile />
          </PrivateRoute>

          <Route path="/404" component={NotFound} />
          <Redirect to="/404" />
        </Switch>
      </div>
      {
        <Footer />
      }
    </Router>
  );
}

export default App;
