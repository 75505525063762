import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import { Row, Col, Button, Typography, Space } from 'antd';

function Home(props: any) {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <Row justify='center' align='middle' >
        <Col xs={20} sm={16} lg={16}>
          <Row justify='center' align='middle' >
            <Col xs={20} sm={8} lg={8}>
              <img alt="logo" style={{ width: '100%', marginBottom: 20, marginTop: 20 }} src={process.env.PUBLIC_URL + '/logo-fondef.png'} />
            </Col>
          </Row>

          <Space direction={'vertical'} size={16} style={{ width: '100%' }}>
            <Row justify='center' align='middle' >
              <Typography.Title level={1} style={{ textAlign: 'center' }}>{t('title')}</Typography.Title>
            </Row>

            <Row justify='center' align='middle' >
              <Typography.Text style={{ textAlign: 'center' }}>{t('home.summary')}</Typography.Text>
            </Row>

            <Row justify='center' align='middle' >
              <Button type='primary' onClick={() => history.push('/buildreport')}>{
                props.authState.authenticated ?
                  t('buildReport.buildReport') :
                  t('navbar.login')
              }
              </Button>
            </Row>

          </Space>
        </Col>
      </Row>

    </>
  );
};

const mapStatetoProps = (state: any) => {
  return {
    authState: state.auth
  }
}

export default connect(mapStatetoProps)(Home);
