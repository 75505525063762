import { useEffect, useState, useCallback, useRef } from 'react'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { Row, Col, Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import useFetch from '../../hooks/useFetch'
import PatientsTable from './PatientsTable'

interface IDoc {
  id: string,
  upload_date: string
};
interface IDocs {
  [dpoae: string]: IDoc[] | [],
  moca: IDoc[] | [],
  mmse: IDoc[] | [],
  survey: IDoc[] | [],
  audiometry: IDoc[] | [],

};
interface IState {
  patientId: string,
  reportId: string,
  name: string,
  nationalId: string,
  age: number,
  sex: string,
  educationLevel: number,
  center: string,
  documents: IDocs | null
};

interface IProps {
  token: string;
}

function PatientTable({ token }: IProps) {
  const { t } = useTranslation();
  const history = useHistory();

  const [tableState, setTableState] = useState<IState[] | []>([])
  const hasFetchedData = useRef(false);
  const [fetchState, setFetchState] = useFetch();

  const fetchStable = useCallback((token) => {
    const url = `${process.env.REACT_APP_API_URL}v1/reports/`;
    const headers = {
      Authorization: `JWT ${token}`
    };
    setFetchState({ url: url, method: 'GET', headers: headers }, true);
    hasFetchedData.current = true;
  }, [setFetchState])

  useEffect(() => {
    if (!hasFetchedData.current) {
      fetchStable(token)
    }
  }, [token, fetchStable])

  useEffect(() => {
    if (fetchState.data === null) {
      return;
    }
    const processedData = fetchState.data.map((obj: any) => {
      const processedDocs = {
        dpoae: obj.patient.documents.filter((docObj: any) => docObj.type.toUpperCase() === 'DPOAE'),
        moca: obj.patient.documents.filter((docObj: any) => docObj.type.toUpperCase() === 'MOCA'),
        mmse: obj.patient.documents.filter((docObj: any) => docObj.type.toUpperCase() === 'MMSE'),
        survey: obj.patient.documents.filter((docObj: any) => docObj.type.toUpperCase() === 'SURVEY'),
        audiometry: obj.patient.documents.filter((docObj: any) => docObj.type.toUpperCase() === 'AUDIOMETRY'),
      }
      return ({
        reportId: obj.id,
        patientId: obj.patient.id,
        name: `${obj.patient.first_name} ${obj.patient.last_name}`,
        nationalId: obj.patient.national_id,
        age: moment().diff(new Date(obj.patient.birth_date), 'years'),
        educationLevel: obj.patient.educational_level,
        sex: obj.patient.sex,
        center: obj.center ? obj.center.name : '',
        documents: processedDocs
      }
      )
    });
    setTableState(processedData)
  }, [fetchState])

  const onClickNew = () => {
    history.push('/buildreport')
  }

  return (
    <>
      <Row justify='center' align='middle' style={{ margin: '3%' }}>
        <Col>
          <Typography.Title level={1}>
            {t('commons.patientsTable')}
          </Typography.Title>
        </Col>
      </Row>

      <Row justify='center' align='middle' style={{ marginBottom: '3%' }}>
        <Col span={20}>
          <Button type='primary' size='large' onClick={onClickNew}>
            {t('commons.add')}
          </Button>
        </Col>
      </Row>

      <Row justify='center' align='top' >
        <Col span={20}>
          <PatientsTable data={tableState} />
        </Col>
      </Row>
    </>
  );
};

const mapStatetoProps = (state: any) => {
  return {
    token: state.auth.token
  }
}

export default connect(mapStatetoProps)(PatientTable);