import * as types from '../actions';

const initialState = {
  authenticated: false,
  authError: false,
  token: '',
  userId: '',
  email: '',
  firstName: '',
  lastName: '',
  nationalId: '',
  institution: {}
};

export const authReducer = (state = initialState, action) => {
  const response = action.response;

  switch (action.type) {
    case types.LOGIN_USER_SUCCESS:
      return { ...state, authenticated: true, authError: false, ...response };
    case types.LOGIN_USER_ERROR:
      return { ...state, response, authError: true };

    case types.LOGOUT_USER_SUCCESS:
      return { ...initialState };
    case types.LOGOUT_USER_ERROR:
      return { ...initialState };
    default:
      return state;
  }
};
