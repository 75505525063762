import { takeEvery, put, call, all } from 'redux-saga/effects';
import { loginUserService, logoutUserService } from '../services/authServices';

import * as types from '../actions'

/**
 * 
 * @param {user, pass} payload 
 */
export function* loginSaga(payload) {
  try {
    const response = yield call(loginUserService, payload);
    yield put({ type: types.LOGIN_USER_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.LOGIN_USER_ERROR, error })
  }
}

/**
 * 
 * @param {token} payload 
 */
export function* logoutSaga(payload) {
  try {
    yield call(logoutUserService, payload);
    yield put({ type: types.LOGOUT_USER_SUCCESS });
  } catch (error) {
    yield put({ type: types.LOGOUT_USER_ERROR, error })
  }
}

function* watchUserLogin() {
  yield takeEvery(types.LOGIN_USER, loginSaga)
}

function* watchUserLogout() {
  yield takeEvery(types.LOGOUT_USER, logoutSaga)
}

export function* authSaga() {
  yield all([
    watchUserLogin(),
    watchUserLogout()
  ]);
}