import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { Form, Input, Button, Row, Col, Typography, Modal, message, Spin } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import useFetch from '../../hooks/useFetch'


function ResetPass() {
  const history = useHistory();
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchState, setEnpoint] = useFetch();

  useEffect(() => {
    if (fetchState.statusCode === 200) {
      showModal();
    }
    if (fetchState.statusCode >= 400) {
      message.error(t('error.download'))
    }
    setIsLoading(false)
  }, [fetchState.statusCode, t]
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    history.push('/');
  };

  const onFinish = (values: any) => {
    setIsLoading(true);
    const url = `${process.env.REACT_APP_API_URL}v1/accounts/auth/password/reset/`;
    const body = JSON.stringify({ email: values.email });
    setEnpoint({ url: url, method: 'POST', body: body }, true);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div style={{ margin: '3%' }}>
      <Modal
        title={t('warning.notice')}
        visible={isModalVisible}
        onCancel={handleOk}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        footer={[
          <Button type="primary" onClick={handleOk}>
            OK
          </Button>
        ]}>
        {t('login.mailpass')}
      </Modal>

      <Row justify='center' align='middle' >
        <Typography.Title level={1}>{t('login.forgot')}</Typography.Title>
      </Row>
      <Row justify='center' align='middle' style={{ margin: 20 }}>
        <Typography>{t('login.recover')}</Typography>
      </Row>
      <Row justify='center' align='middle' >
        <Col sm={18} md={12} lg={6}>
          <Form
            name='basic'
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label='Email'
              name='email'
              rules={[{ required: true, message: 'Please input your Email!' }]}
            >
              <Input prefix={<UserOutlined />} placeholder='Username' />
            </Form.Item>

            <Form.Item>
              {
                isLoading ?
                  <Spin /> :
                  <Button type='primary' htmlType='submit' style={{ marginTop: 15 }}>
                    {t('commons.submit')}
                  </Button>
              }
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default ResetPass;