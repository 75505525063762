import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

function Footer() {
  const { t } = useTranslation();

  return (
    <div>
      <Row justify='center' align='middle' >
        <Col>
            {t('footer')}
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
