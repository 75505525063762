import * as types from './index';

/**
 * 
 * @param {username, pass} credentials 
 * @returns 
 */
export const loginUserAction = (credentials) => {
  return {
    type: types.LOGIN_USER,
    credentials
  }
};

export const logoutUserAction = (token) => {
  return {
    type: types.LOGOUT_USER,
    token
  }
};