// @ts-nocheck
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, Input, Button, Row, Col, Typography } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { loginUserAction } from '../../actions/authActions'

function Login(props: any) {
  const [state, setState] = useState({ waiting: false })
  const { t } = useTranslation();
  const history = useHistory()

  useEffect(() => {
    setState({ waiting: false })
  }, []);

  useEffect(() => {
    if (props.authState.authenticated) {
      history.push('/buildreport')
    }
  }, [props.authState.authenticated, history]);

  const onFinish = (values: any) => {
    props.login({ user: values.username, pass: values.password })

    // Waiting afthe the user submit the data
    setState({ waiting: true });
  };

  /**
   * If the login fails show msg, hide msg when user is typing the user or pass (waiting == false)
   * show msg if the global state authError it's true and the user is not typing
   * @returns 
   */
  const showCredentialErrors = () => {
    if (state.waiting  && props.authState.authError){
      return true;
    }
    return false;
  };

  return (
    <div style={{ margin: '3%' }}>
      <Row justify='center' align='middle' >
        <Typography.Title level={1}>{t('login.login')}</Typography.Title>
      </Row>
      <Row justify='center' align='middle' >
        <Col sm={18} md={12} lg={6}>
          <Form
            name='normal_login'
            onFinish={onFinish}
            onChange={() => setState({ waiting: false })}
          >
            <Form.Item
              name='username'
              rules={[{ required: true, message: t('warning.input') }]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder={t('login.email')} />
            </Form.Item>
            <Form.Item
              name='password'
              rules={[{ required: true, message: t('warning.input') }]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                type='password'
                placeholder={t('login.password')}
              />
            </Form.Item>
            {showCredentialErrors() ? <Typography.Text type='danger'>{t('login.error')}</Typography.Text> : null}
            <Form.Item>
              <Link to={'/resetpassword'}>
                {t('login.forgot')}
              </Link>
            </Form.Item>
            <Form.Item>
              <Button type='primary' htmlType='submit'>
                {t('login.login')}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

const mapStatetoProps = (state: any) => {
  return {
    authState: state.auth
  }
}

const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    login: (credentials: any) => {
      dispatch(loginUserAction(credentials))
    },
  }
}
export default connect(mapStatetoProps, mapDispatchtoProps)(Login);
