import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Row, Col, Table, Button } from 'antd';
import { DocsModal } from '../../commons/index'

import '../PrintStyles.css'

const columns = [
  {
    title: '',
    dataIndex: 'keyColumn',
    key: 'keyColumn',
  },
  {
    title: '',
    dataIndex: 'valueColumn',
    key: 'valueColumn',
  },
]

interface IDoc {
  id: string,
  upload_date: string
};
interface IDocs {
  [dpoae: string]: IDoc[] | [],
  moca: IDoc[] | [],
  mmse: IDoc[] | [],
  survey: IDoc[] | [],
  audiometry: IDoc[] | [],
};
interface IObj {
  patientId: string,
  name: string,
  nationalId: string,
  age: number,
  sex: string,
  educationLevel: number,
  center: string
  documents: IDocs | null
}
interface IProps {
  data: IObj | null;
}

interface ICol {
  key: string,
  keyColumn: string,
  valueColumn: string | number
}

function ReportTable({ data }: IProps) {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tableState, setTableState] = useState<ICol[] | []>([]);

  useEffect(() => {
    const formatTable = () => {
      // Check if this works if not, move outside the use effect S
      const newData: ICol[] = [
        {
          key: '1',
          keyColumn: t('patientTable.name'),
          valueColumn: data!.name,
        },
        {
          key: 'age',
          keyColumn: t('patientTable.age'),
          valueColumn: data!.age,
        },
        {
          key: 'sex',
          keyColumn: t('patientTable.sex'),
          valueColumn: data!.sex,
        },
        {
          key: 'educationLevel',
          keyColumn: t('patientTable.educationLevel'),
          valueColumn: data!.educationLevel,
        }
        ,
        {
          key: 'dni',
          keyColumn: t('patientTable.nationalId'),
          valueColumn: data!.nationalId,
        }
      ];
      return newData;
    }

    if (data) {
      const newData = formatTable();
      setTableState(newData);
    }
  }, [data, t])

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };


  return (
    <>
      <DocsModal modalData={{ documents: data != null ? data.documents : null, user: t('report.currentPatient') }} isModalVisible={isModalVisible} handleOk={handleOk} />

      <Row justify='center' align='middle' style={{ marginTop: 20 }}>
        <Button className="no-print" type='primary' onClick={showModal}>{t('commons.showDocuments')}</Button>
      </Row>

      <Row justify='center' align='middle' >
        <Col span={16} >
          <Table columns={columns} dataSource={tableState} pagination={false} />
        </Col>
      </Row>
    </>
  );
};

export default ReportTable;