import { useTranslation } from 'react-i18next';
import { Modal, Button, List, message } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment'

interface IDoc {
  id: string,
  upload_date: string
};

interface IDocs {
  [dpoae: string]: IDoc[] | [],
  moca: IDoc[] | [],
  mmse: IDoc[] | [],
  survey: IDoc[] | [],
  audiometry: IDoc[] | [],
};

interface IModalData {
  documents: IDocs | null,
  user: string
};

interface IProps {
  modalData: IModalData | undefined,
  isModalVisible: boolean,
  handleOk: () => void,
  token: string
};

function DocsModal({ modalData, isModalVisible, handleOk, token }: IProps) {
  const { t } = useTranslation();

  const downloadDoc = (id: string) => {
    const url = `${process.env.REACT_APP_API_URL}v1/reports/files/${id}`;
    const headers = {
      Authorization: `JWT ${token}`
    };
    const parameters = {
      method: 'GET',
      headers: headers
    }
    fetch(url, parameters).then(
      response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = `document_${id}.pdf`;
          a.click();
        });
      })
      .catch((e) => {
        console.log(e)
        message.error(t('error.download'))
      }
      );
  }

  const checkData = () => {
    if (modalData) {
      if (modalData.documents)
        return true;
    }
    return false;
  }

  return (
    <Modal
      visible={isModalVisible}
      title={modalData?.user}
      onOk={handleOk}
      onCancel={handleOk}
      footer={[
        <Button type="primary" onClick={handleOk} key={'ok'}>
          OK
        </Button>
      ]}>
      <div>
        {
          // if modal data map the keys (documents)
          checkData() ?
            Object.keys(modalData!.documents!).map((key, idx) => (
              modalData!.documents![key].length !== 0 ?
                <List itemLayout="horizontal" header={key} key={idx}>
                  {
                    modalData!.documents![key].map((obj: IDoc) =>
                      <List.Item key={obj.id}>
                        <List.Item.Meta
                          avatar={<Button onClick={() => downloadDoc(obj.id)} size="small" >{t('commons.download')}</Button>}
                          description={moment(obj.upload_date).format('DD-MM-YYYY')}
                        />
                      </List.Item>
                    )
                  }
                </List>
                // No documents for the current key
                : null
            )
            )
            :
            // No data
            null
        }
      </div>
    </Modal>
  )
}

const mapStatetoProps = (state: any) => {
  return {
    token: state.auth.token
  }
}

export default connect(mapStatetoProps)(DocsModal);